<template>
  <k-basic-modal-layout>
    <template v-slot:header>
      <div class="panel-header">
        <div class="d-flex align-items-center pr-20">
          <span class="title-border bg-blue-cyan mr-20"></span>
          <h1 class="hasab-title">Jelszó módosítása</h1>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <b-form :id="formId">
        <div class="container px-2">
          <div class="row no-gutters">
            <div class="col-12">
              <b-form-group label="Régi jelszó">
                <k-basic-text-input
                  v-model="$v.form.RegiJelszo.$model"
                  type="password"
                  autocomplete="new-password"
                  :validator="$v.form.RegiJelszo"
                  autofocus
                ></k-basic-text-input>
              </b-form-group>
            </div>
            <div class="col-12 pr-2">
              <b-form-group label="Új jelszó">
                <k-basic-text-input
                  v-model="$v.form.UjJelszo.$model"
                  type="password"
                  autocomplete="new-password"
                  :validator="$v.form.UjJelszo"
                ></k-basic-text-input>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group label="Új jelszó megerősítése">
                <k-basic-text-input
                  v-model="$v.form.UjJelszo2.$model"
                  type="password"
                  autocomplete="new-password"
                  :validator="$v.form.UjJelszo2"
                ></k-basic-text-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-form>
    </template>
    <template v-slot:footer>
      <div class="footer justify-content-between">
        <k-button
          variant="dark"
          label="Mégsem"
          @click="Close()"
          icon="fas fa-times"
        ></k-button>
        <k-button
          class="ml-2"
          variant="primary"
          label="Mehet"
          :form="formId"
          icon="fa-save"
          :loading="formSaving"
          @click="Mentes"
        ></k-button>
      </div>
    </template>
  </k-basic-modal-layout>
</template>

<script>
import required from 'vuelidate/src/validators/required';
import minLength from 'vuelidate/src/validators/minLength';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { projektService } from '../../services/ProjektService';
import { useModalHelper } from '../../utils/modal';
import { azonosito, notSameAs } from '../../utils/validations';
import { userService } from '../../services/UserService';

export default {
  name: 'uj-jelszo-modal',
  data() {
    return {
      formSaving: false,
      form: {
        RegiJelszo: '',
        UjJelszo: '',
        UjJelszo2: '',
      },
    };
  },
  setup(props, context) {
    let { formId, IsVueFormValid } = useModalHelper(props, context);
    return { formId, IsVueFormValid };
  },
  async mounted() {
    this.$emit('set-loading', true);
    this.$emit('set-loading', false);
  },

  created() {},
  methods: {
    Close() {
      this.$emit('close');
    },
    async Mentes() {
      if (!this.IsVueFormValid(this)) {
        console.log(this.$v);
        return;
      }

      this.formSaving = true;
      try {
        await userService.Jelszomodositas(this.form);
        NotificationFunctions.SuccessAlert(
          'Jelszó módosítása',
          'A jelszó módosítása sikeresen megtörtént'
        );
        this.Close();
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.formSaving = false;
    },
  },
  validations() {
    let validations = {
      form: {
        RegiJelszo: { required },
        UjJelszo: { required, minLengthJelszo: minLength(8) },
        UjJelszo2: {
          required,
          egyezoJelszo: notSameAs(this.form.UjJelszo),
        },
      },
    };
    return validations;
  },
  computed: {},
  watch: {},
  props: {
    modal: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
